<template>
  <div id="product-detail-page" class="w-100">
    <ProductDetailComp></ProductDetailComp>
  </div>
</template>

<script>
import ProductDetailComp from "@/components/ProductDetailComp.vue";
export default {
  components: { ProductDetailComp },
};
</script>

<style></style>
