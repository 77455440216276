<template>
  <div id="user-management">
    <div class="detail-management">
      <h2>DANH SÁCH KHÁCH HÀNG</h2>
      <div class="list-action-btn d-flex flex-row justify-content-between">
        <div class="search position-relative">
          <input
            type="text"
            placeholder="Tìm kiếm theo tên, số điện thoại, email..."
            class="form-control search-form"
            v-model="searchKeyword"
            @keydown.enter="triggerSearch()"
          />
          <img
            @click="searchUserByKeyWord()"
            class="img-absolute"
            style="cursor: pointer"
            :src="require('@/assets/images/tabler_search.png')"
          />
        </div>
        <div class="list-button">
          <button
            class="common-btn mx-2"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Bộ lọc
            <img :src="require('@/assets/images/filter.png')" />
          </button>
          <button class="common-btn mx-2" @click="refreshDataUserInfo()">
            Cập nhập
            <img
              :class="{ rotate: isLoadingUserInfo }"
              :src="require('@/assets/images/refresh.png')"
            />
          </button>
          <button class="common-btn mx-2" v-b-modal.modal-1>
            Thêm KH
            <img :src="require('@/assets/images/user_add.png')" />
          </button>
        </div>
      </div>
      <div class="collapse mt-2" id="collapseExample">
        <div class="card card-body">
          <div class="filter-div">
            <div class="header-filter">BỘ LỌC</div>
            <div class="body-filter">
              <div class="first-row d-flex flex-wrap">
                <div class="filter-item first-name">
                  <label for="first_name" class="form-label"
                    >Tên khách hàng</label
                  >
                  <input
                    type="input"
                    class="form-control"
                    id="first_name"
                    v-model="filterCustomerCondition.name"
                    required
                  />
                </div>
                <div class="filter-item item-center last-name">
                  <label for="last_name" class="form-label">Tên công ty</label>
                  <input
                    type="input"
                    class="form-control"
                    id="last_name"
                    v-model="filterCustomerCondition.company_name"
                    required
                  />
                </div>
                <div class="filter-item phone-number">
                  <label for="phone_number" class="form-label"
                    >Số điện thoại</label
                  >
                  <input
                    type="input"
                    class="form-control"
                    id="phone_number"
                    v-model="filterCustomerCondition.phone"
                    required
                  />
                </div>
                <div class="filter-item email">
                  <label for="email" class="form-label">Email</label>
                  <input
                    type="input"
                    class="form-control"
                    id="email"
                    v-model="filterCustomerCondition.email"
                    required
                  />
                </div>
                <div class="list-option-checkbox">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    class="list-checkbox"
                    v-model="filterCustomerCondition.statuses"
                    :options="STATUS_OPTIONS_CHECKBOX"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </div>
                <div class="filter-item-submit button_submit">
                  <button
                    class="common-btn mx-2"
                    @click="getUserInfoSearch()"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Xác nhận
                  </button>

                  <button
                    class="clear-btn mx-2"
                    @click="clearFilter()"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-data">
        <div class="header-table d-flex flex-row justify-content-between">
          <div class="header-item-data no-item">STT</div>
          <div class="header-item-data user-item">Tên khách hàng</div>
          <div class="header-item-data phone-item">Số điện thoại</div>
          <div class="header-item-data email-item">Email</div>
          <div class="header-item-data company-name">
            Tên công ty / Ngành nghề
          </div>
          <div class="header-item-data required-item flex-grow-1">Nhu cầu</div>
          <div class="header-item-data created_date">Ngày nhập</div>
          <div class="header-item-data updated_date">CSKH gần nhất</div>
          <div class="header-item-data status-item">Tình trạng</div>
          <div class="header-item-data timeline">Timeline</div>
          <div class="header-item-data note">Ghi chú</div>
        </div>
        <div class="body-table d-flex flex-column">
          <div
            v-for="(record, idx) in listUserInfoShow"
            @contextmenu.prevent="openNewTab($event, record)"
            class="record-table d-flex flex-row justify-content-between"
            :key="idx"
            :class="{
              'bg-even-record': idx % 2 != 0,
              'bg-odd-record': idx % 2 == 0,
            }"
          >
            <div class="body-item-data no-item">
              <span>{{ idx + 1 }}</span>
            </div>
            <div
              class="body-item-data user-item"
              @click="openDetailUserPage(record)"
            >
              <span
                ><b>{{ record.name }}</b></span
              >
            </div>
            <div class="body-item-data phone-item">
              <span>{{ record.phone }}</span>
            </div>
            <div class="body-item-data email-item">
              <span>{{ record.email }}</span>
            </div>
            <div class="body-item-data company-name">
              <span>{{ record.company_name }}</span>
            </div>
            <div class="body-item-data required-item flex-grow-1">
              <span class="text-container">
                <template v-if="record?.demand?.expect_area">
                  {{ record?.demand?.expect_area }} -
                </template>
                <template v-if="record?.demand?.budget">
                  {{ record?.demand?.budget }} -
                </template>
                <template v-if="record?.demand?.address?.expect_location">
                  {{ record?.demand?.address?.expect_location }} -
                </template>
                <template v-if="record?.demand?.used_time">
                  {{ record?.demand?.used_time }} -
                </template>
                {{ record?.special_note }}
              </span>
            </div>
            <div class="body-item-data created_date">
              <span>{{ record.member_create_customer_at }}</span>
            </div>
            <div class="body-item-data updated_date">
              <span>{{ record.customer_care_at }}</span>
            </div>
            <div class="body-item-data status-item">
              <span>{{ getStatusName(record.status) }}</span>
            </div>
            <div class="body-item-data timeline">
              <span>{{ record.status_updated_at }}</span>
            </div>
            <div class="body-item-data note">
              <span>{{ record.note }}</span>
            </div>
          </div>
        </div>
      </div>
      <pagination
        class="mt-3"
        v-model="filterCustomerCondition.pagination.page"
        :per-page="perPage"
        :records="listUserInfo?.length"
        @paginate="myCallback"
      />
      <userInfoModal
        :userInfo="newUserInfo"
        @updateUserInfo="updateUserInfo"
        title="Tạo khách hàng"
      ></userInfoModal>
    </div>
  </div>
</template>

<script>
// import generateDummyUserData from "@/assets/dumydata/userInfor";
import userInfoModal from "@/components/common/UserInfoModal.vue";
import { requestAsync } from "@/requester/requestAsync";
import { mapGetters } from "vuex";
import moment from "moment";
// import formatDate from "@/ultils/formatDate";
export default {
  data() {
    return {
      listUserInfo: [],
      perPage: 50,
      isLoadingUserInfo: false,
      // for filter
      filterCustomerCondition: {
        name: "",
        company_name: "",
        phone: "",
        email: "",
        statuses: [],
        pagination: {
          limit: 50,
          order: "desc",
          page: 1,
        },
      },
      STATUS_OPTIONS_CHECKBOX: [
        {
          text: "Mới",
          value: "NEW",
        },
        {
          text: "Khó tiếp cận",
          value: "INACCESSIBLE",
        },
        {
          text: "Báo giá",
          value: "QUOTE",
        },
        {
          text: "Đi xem",
          value: "WATCHED",
        },
        {
          text: "Thương lượng",
          value: "NEGOTIATION",
        },
        {
          text: "Tạm hoãn",
          value: "PENDING",
        },
        {
          text: "Thành công",
          value: "SUCCESS",
        },
        {
          text: "Thất bại",
          value: "FAIL",
        },
      ],
      newUserInfo: null,
      searchKeyword: null,
    };
  },
  components: {
    userInfoModal,
  },
  computed: {
    listUserInfoShow() {
      return this.listUserInfo?.slice(
        (this.filterCustomerCondition.pagination.page - 1) * this.perPage,
        this.filterCustomerCondition.pagination.page * this.perPage
      );
    },
    ...mapGetters("user", ["getMemberId"]),
  },
  methods: {
    initNewUserInfo() {
      let data = {
        company_name: "",
        connect_info: {
          fifth_step: null,
          first_step: "",
          fourth_step: "",
          second_step: "",
          third_step: "",
        },
        current_home: "",
        customer_care_at: moment().format("YYYY-MM-DD"),
        demand: {
          address: {
            city: "",
            district: "",
            expect_location: "",
          },
          budget: "",
          expect_area: "",
          num_person: "",
          used_time: "",
          vehicle: {
            car: "",
            motorbike: "",
          },
        },
        demand_v2: "",
        email: "",
        member_create_customer_at: moment().format("YYYY-MM-DD"),
        member_id: "",
        name: "",
        note: "",
        phone: "",
        status: "NEW",
        special_note: "",
        status_updated_at: moment().format("YYYY-MM-DD"),
      };
      this.$set(this, "newUserInfo", data);
    },
    getStatusName(status) {
      switch (true) {
        case status === "NEW":
          return "Mới";
        case status === "INACCESSIBLE":
          return "Khó tiếp cận";
        case status === "QUOTE":
          return "Báo giá";
        case status === "WATCHED":
          return "Đi xem";
        case status === "NEGOTIATION":
          return "Thương lượng";
        case status === "PENDING":
          return "Tạm hoãn";
        case status === "SUCCESS":
          return "Thành công";
        case status === "FAIL":
          return "Thất bại";
      }
    },
    clearFilter() {
      this.filterCustomerCondition = {
        name: "",
        company_name: "",
        phone: "",
        email: "",
        statuses: [],
        pagination: {
          limit: 50,
          order: "desc",
          page: 1,
        },
      };
      this.getUserInfo();
    },
    refreshDataUserInfo() {
      this.isLoadingUserInfo = true;
      console.log(this.isLoadingUserInfo);
      setTimeout(() => {
        this.getUserInfo();
        this.isLoadingUserInfo = false;
        console.log(this.isLoadingUserInfo);
      }, 1000);
    },
    openNewTab(event, user) {
      if (event.button === 2) {
        // Check if the right mouse button is clicked
        event.preventDefault(); // Prevent the default context menu
        window.open("/user-info-detail/" + user.id, "_blank");
      }
    },
    async getUserInfo(page = 1) {
      this.filterCustomerCondition.pagination.page = page;
      let res = await requestAsync.get("customers");
      if (res?.items) {
        this.listUserInfo = res?.items;
      }
    },
    async getUserInfoSearch(page = 1) {
      this.filterCustomerCondition.pagination.page = page;
      let res = await requestAsync.post(
        "customers/search",
        this.filterCustomerCondition
      );
      if (res?.items) {
        this.listUserInfo = res?.items;
      }
    },
    triggerSearch() {
      // Use $nextTick to ensure that searchKeyword is updated before calling searchUserByKeyWord
      this.$nextTick(() => {
        this.searchUserByKeyWord();
      });
    },
    async searchUserByKeyWord(page = 1) {
      this.filterCustomerCondition.pagination.page = page;
      let res = await requestAsync.get(
        `customers/search?keyword=${this.searchKeyword}&page=${page}&limit=50&order=desc`
      );
      if (res?.items) {
        this.listUserInfo = res?.items;
      }
    },
    myCallback: function (page) {
      console.log(page);
    },
    openDetailUserPage(user) {
      this.$router.push("/user-info-detail/" + user.id);
    },
    updateUserInfo(data) {
      console.log(data);
      this.$set(this, "newUserInfo", data?.userInfo);
      if (data?.isCreate) {
        this.createNewUser();
      }
    },
    async createNewUser() {
      // let data = this.formatUserData();
      let res = await requestAsync.post("customers", this.newUserInfo);
      if (res?.id) {
        this.$toast.open({
          message: "Tạo khách hàng mới thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        this.initNewUserInfo();
        this.getUserInfo();
      }
    },
  },
  mounted() {
    // Select all paragraphs with the class VuePagination__count
    const paragraphs = document.querySelectorAll(".VuePagination__count");

    // Loop through each paragraph and remove it
    paragraphs.forEach((paragraph) => {
      paragraph.remove();
    });
  },
  created() {
    this.initNewUserInfo();
    this.getUserInfo();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
#user-management {
  h2 {
    color: $primary-color !important;
  }
  .detail-management {
    padding: 15px 15px 20px;
  }
  .list-action-btn {
    margin-top: 20px;
    .list-button {
      display: flex;
      flex-direction: row;
    }
  }
  .img-absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
  .search-form {
    width: 50%;
    min-width: 400px;
    height: 100%;
  }
  // filter
  .filter-div {
    .header-filter {
      background: rgba($primary-color, 0.6);
      text-align: center;
      padding: 10px 0px;
      color: #fff;
      font-size: 18px;
    }
    .body-filter {
      margin-top: 20px;
      .filter-item {
        width: 30%;
      }
      .item-center {
        margin: 0px 5%;
      }
      .list-option-checkbox {
        width: 30%;
        margin: 10px 5% 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .list-checkbox {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .custom-control {
            min-width: 50%;
          }
          .custom-control-input {
            margin-right: 20px;
          }
        }
      }
      .filter-item-submit {
        width: 30%;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
  // table data
  .table-data {
    font-size: 12px;
    margin-top: 15px;
    overflow-x: auto;
    word-wrap: break-word;
    .header-table {
      background: rgba($primary-color, 0.65);
      color: #fff;
      font-size: 14px;
      width: 100%;
      border-left: solid 1px $border-color;
      border-right: unset;
      .header-item-data {
        text-align: center;
        padding: 15px 0px;
        border: solid 1px $border-color;
        border-left: unset;
      }
    }
    .body-table {
      width: 100%; //dont know
      max-height: calc(100vh - 325px);
      .record-table {
        cursor: pointer;
        border-left: solid 1px $border-color;
      }
      .bg-even-record {
        background: #fff;
      }
      .bg-odd-record {
        background: rgba($primary-color, 0.15);
      }
      .body-item-data {
        text-align: center;
        padding: 12px 5px;
        border: solid 1px $border-color;
        border-left: unset;
        border-top: unset;
      }
    }
    .no-item {
      width: 40px;
      flex: 0 0 40px;
    }
    .phone-item {
      width: 100px;
      flex: 0 0 100px;
    }
    .user-item {
      font-size: 13px;
      width: 130px;
      flex: 0 0 130px;
    }
    .email-item {
      width: 130px;
      flex: 0 0 130px;
    }
    .required-item {
      flex: 1 1 auto; /* Cho phép phần tử này co giãn để chiếm không gian còn lại */
      min-width: 0;
      .text-container {
        word-wrap: break-word; /* Cho phép văn bản xuống dòng khi cần thiết */
        overflow-wrap: break-word; /* Tương tự như word-wrap, hỗ trợ nhiều trình duyệt */
        white-space: normal; /* Cho phép văn bản xuống dòng theo cách thông thường */
      }
    }
    .created_date,
    .updated_date,
    .status-item {
      width: 100px;
      flex: 0 0 100px;
    }
    .company-name {
      width: 120px;
      flex: 0 0 120px;
    }
    .timeline {
      width: 100px;
      flex: 0 0 100px;
    }
    .note {
      width: 150px;
      flex: 0 0 150px;
    }
  }

  // pagination
  .page-link {
    color: $primary-color !important;
  }
  .disabled > .page-link {
    background: rgba($primary-color, 0.15) !important;
  }
  .active > .page-link,
  .page-link.active {
    background: $primary-color !important;
    color: #fff !important;
    border-color: $primary-color !important;
  }
  // rotate
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .rotate {
    animation: rotate 2s linear infinite; /* Rotate animation lasts for 3 seconds, linear timing, and repeats infinitely */
  }
}
</style>
