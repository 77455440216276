const namespaced = true;

const state = {
  filterObj: {
    address: {
      detail: "",
      district: "",
      province: "Thành phố Hồ Chí Minh",
      ward: "",
    },
    building_directions: [],
    floor_area: {
      max: "",
      min: "",
    },
    empty_area_range: {
      max: "",
      min: "",
    },
    manager_company: "",
    manager_name: "",
    manager_phone: "",
    name: "",
    saler_name: "",
    price_range: {
      max: "",
      min: "",
    },
    ranks: [],
    pagination: {
      limit: 50,
      order: "desc",
      page: 1,
    },
  },
};
const getters = {
  getFilterObj(state) {
    let localState = window.localStorage.getItem("crm-product");
    if (localState) {
      state.filterObj = JSON.parse(localState).filterObj;
    }
    return state.filterObj;
  },
  filterHasValue(state) {
    let localState = window.localStorage.getItem("crm-product");
    if (localState) {
      state.filterObj = JSON.parse(localState).filterObj;
    }
    const hasValue = Object.values(state.filterObj).some((value) => {
      if (typeof value === "object" && value !== null) {
        // If the value is an object (excluding null)
        return Object.values(value).some(
          (subValue) => subValue !== "" && subValue !== "Thành phố Hồ Chí Minh"
        );
      } else {
        // If the value is a non-object type
        return value !== "" && value !== "Thành phố Hồ Chí Minh";
      }
    });
    return hasValue;
  },
};
const mutations = {
  setFilterObj(state, filterObj) {
    state.filterObj = filterObj;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-product", saveState);
    return state.filterObj;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
