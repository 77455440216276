export default [
  {
    text: "Mới",
    value: "NEW",
  },
  {
    text: "Khó tiếp cận",
    value: "INACCESSIBLE",
  },
  {
    text: "Báo giá",
    value: "QUOTE",
  },
  {
    text: "Đi xem",
    value: "WATCHED",
  },
  {
    text: "Thương lượng",
    value: "NEGOTIATION",
  },
  {
    text: "Tạm hoãn",
    value: "PENDING",
  },
  {
    text: "Thành công",
    value: "SUCCESS",
  },
  {
    text: "Thất bại",
    value: "FAIL",
  },
];
