<template>
  <div
    id="header-comp"
    class="d-flex flex-row justify-content-between w-100 px-3 py-2"
  >
    <div class="logo-img">
      <img :src="require('@/assets/images/logo.png')" />
    </div>
    <div class="user-logo d-flex flex-col align-items-center">
      <img
        @click="logout()"
        :src="require('@/assets/images/user-profile.png')"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
#header-comp {
  background: #e2e2e2;
  .user-logo {
    cursor: pointer;
  }
}
.logo-img {
  img {
    max-height: 45px;
  }
}
</style>
