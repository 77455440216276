<template>
  <b-modal hide-header hide-footer id="modal-1" ref="modalUserInfo">
    <div class="add-user-modal">
      <div class="header-add-user">
        <span>THÊM KHÁCH HÀNG</span>
      </div>
      <div class="body-add-user">
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="user_name" class="form-label">Tên khách hàng</label>
            <input
              type="input"
              class="form-control"
              id="user_name"
              v-model="userInfoData.name"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="company_name" class="form-label">Tên công ty</label>
            <input
              type="input"
              class="form-control"
              id="company_name"
              v-model="userInfoData.company_name"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="phone_number" class="form-label">Số điện thoại</label>
            <input
              type="input"
              class="form-control"
              id="phone_number"
              v-model="userInfoData.phone"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="email" class="form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="userInfoData.email"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="created_at" class="form-label">Ngày nhập</label>
            <input
              type="date"
              class="form-control"
              id="created_at"
              v-model="userInfoData.member_create_customer_at"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="updated_at" class="form-label">CSKH gần nhất</label>
            <input
              type="date"
              class="form-control"
              id="updated_at"
              v-model="customerCareAtUpdated"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="acreage_require" class="form-label">Diện tích</label>
            <input
              type="input"
              class="form-control"
              id="acreage_require"
              v-model="userInfoData.demand.expect_area"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="used_time_require" class="form-label"
              >Thời gian sử dụng</label
            >
            <input
              type="input"
              class="form-control"
              id="used_time_require"
              v-model="userInfoData.demand.used_time"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="budget_require" class="form-label">Ngân sách</label>
            <input
              type="input"
              class="form-control"
              id="budget_require"
              v-model="userInfoData.demand.budget"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="location_require" class="form-label">Khu vực</label>
            <input
              type="input"
              class="form-control"
              id="location_require"
              v-model="userInfoData.demand.address.expect_location"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item">
            <label for="total_member_require" class="form-label"
              >Số lượng nhân viên</label
            >
            <input
              type="input"
              class="form-control"
              id="total_member_require"
              v-model="userInfoData.demand.num_person"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="total_trans" class="form-label"
              >Số lượng p/tiện (xe máy, ô tô)</label
            >
            <input
              type="input"
              class="form-control"
              id="total_trans"
              v-model="userInfoData.demand.vehicle.car"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item d-flex flex-column">
            <label for="current_home" class="form-label"
              >Đang ở toà nhà nào</label
            >
            <input
              type="input"
              class="form-control"
              id="current_home"
              v-model="userInfoData.current_home"
              required
            />
          </div>
          <div class="user-info-item">
            <label for="special_note" class="form-label"
              >Yêu cầu đặc biệt</label
            >
            <input
              type="input"
              class="form-control"
              id="special_note"
              v-model="userInfoData.special_note"
              required
            />
          </div>
        </div>
        <div class="row-user-info mt-2">
          <div class="user-info-item d-flex flex-column">
            <label for="total_member_require" class="form-label"
              >Tình trạng</label
            >
            <b-form-select
              v-model="userInfoData.status"
              :options="STATUS_OPTIONS_CHECKBOX"
            ></b-form-select>
          </div>
          <div class="user-info-item">
            <label for="total_trans" class="form-label">Timeline</label>
            <input
              type="date"
              class="form-control"
              id="total_trans"
              v-model="userInfoData.status_updated_at"
              required
            />
          </div>
        </div>
        <div class="mt-2">
          <div class="user-info-item">
            <label for="user_name" class="form-label">Ghi chú</label>
            <textarea
              class="form-control"
              rows="3"
              id="user_name"
              v-model="userInfoData.note"
              required
            >
            </textarea>
          </div>
        </div>
        <div class="mt-3">
          <div class="button-submit d-flex flex-row justify-content-center">
            <button @click="submitUserInfo()" class="common-btn mx-auto">
              {{ title }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
export default {
  props: {
    userInfo: Object,
    title: String,
  },
  data() {
    return {
      userInfoData: null,
      customerCareAtUpdated: moment().format("YYYY-MM-DD"),
      STATUS_OPTIONS_CHECKBOX: [
        {
          text: "Mới",
          value: "NEW",
        },
        {
          text: "Khó tiếp cận",
          value: "INACCESSIBLE",
        },
        {
          text: "Báo giá",
          value: "QUOTE",
        },
        {
          text: "Đi xem",
          value: "WATCHED",
        },
        {
          text: "Thương lượng",
          value: "NEGOTIATION",
        },
        {
          text: "Tạm hoãn",
          value: "PENDING",
        },
        {
          text: "Thành công",
          value: "SUCCESS",
        },
        {
          text: "Thất bại",
          value: "FAIL",
        },
      ],
    };
  },
  watch: {
    userInfo: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$set(this, "userInfoData", this.userInfo);
      },
    },
  },
  methods: {
    submitUserInfo() {
      this.userInfoData.customer_care_at = this.customerCareAtUpdated;
      this.userInfoData.name = this.userInfoData.name.trim();
      this.userInfoData.phone = this.userInfoData.phone.trim();
      this.userInfoData.email = this.userInfoData.email.trim();
      this.userInfoData.company_name = this.userInfoData.company_name.trim();
      let data = {
        isCreate: true,
        userInfo: this.userInfoData,
      };
      this.$emit("updateUserInfo", data);
      this.$refs.modalUserInfo.hide();
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
// modal
.modal-body {
  padding: 0 !important;
}
.add-user-modal {
  .header-add-user {
    text-align: center;
    background: rgba($primary-color, 0.65);
    color: white;
    padding: 15px 0px;
    font-size: 18px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .body-add-user {
    padding: 15px;
    label {
      color: $primary-color;
    }
    .row-user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .user-info-item {
        width: 48%;
      }
      .w-30 {
        width: 30%;
      }
    }
    .custom-select {
      padding: 5px;
      width: 100%;
    }
  }
}
</style>
