<template>
  <div id="user-info-detail-page" class="w-100">
    <UserInfoDetailComp></UserInfoDetailComp>
  </div>
</template>

<script>
import UserInfoDetailComp from "@/components/UserInfoDetailComp.vue";
export default {
  components: { UserInfoDetailComp },
};
</script>

<style></style>
