<template>
  <div class="login page">
    <loginComp title="Login"></loginComp>
  </div>
</template>
<script>
import LoginComp from "@/components/LoginComp.vue";
export default {
  components: {
    loginComp: LoginComp,
  },
};
</script>
