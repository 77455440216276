<template>
  <div id="login-page">
    <img class="img-logo" alt="logo image" src="@/assets/images/logo.png" />
    <h2 class="mt-3 fw-bold">{{ title }}</h2>
    <form class="mt-3" @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="email" class="form-label">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          v-model="emailInput"
          required
        />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          v-model="passwordInput"
          required
        />
      </div>
      <template v-if="errorMsg.length > 0">
        <div class="msg-err">{{ errorMsg }}</div>
      </template>
      <button
        type="submit"
        class="common-btn-submit btn btn-primary mt-3 py-2 px-4"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script>
import { requestAsync } from "@/requester/requestAsync";
import { jwtDecode } from "jwt-decode";
import { mapMutations } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emailInput: "",
      passwordInput: "",
      errorMsg: "",
    };
  },
  methods: {
    ...mapMutations("user", ["setToken", "setMemberId"]),
    getMemberId(decoded) {
      let regex = /([0-9a-fA-F]{24})/;
      // Extracting the hexadecimal portion using match method
      let match = decoded.id.match(regex);
      // Checking if match found
      let objectId = null;
      if (match) {
        objectId = match[0];
      } else {
        console.log("No ObjectID found.");
      }
      return objectId;
    },
    async submitForm() {
      let reqData = {
        username: this.emailInput,
        password: this.passwordInput,
      };
      let res = await requestAsync.post("auth/sign-in", reqData);
      if (res?.token) {
        const decoded = jwtDecode(res?.token);
        let objectId = this.getMemberId(decoded);
        this.setToken(res?.token);
        this.setMemberId(objectId);
        this.errorMsg = "";
        this.$router.push("/user-management");
      } else {
        this.errorMsg = "Đăng nhập thất bại, vui lòng đăng nhập lại!";
      }
    },
  },
};
</script>

<style lang="scss">
#login-page {
  width: 100%;
  max-width: 500px;
  margin: 0px auto;
  margin-top: 100px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .img-logo {
    max-width: 350px;
    margin: auto;
  }
}
</style>
