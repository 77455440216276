import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import RegisterPage from "../views/RegisterPage.vue";
import UserPage from "../views/UserManagementPage.vue";
import UserInfoDetail from "../views/UserInfoDetailPage.vue";
import ProductPage from "../views/ProductManagementPage.vue";
import ProductInfoDetail from "../views/ProductDetailPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: () => {
      // Check if token exists
      const token = JSON.parse(localStorage.getItem("crm-user"))?.token;
      console.log("token");
      if (token) {
        // If token exists, redirect to '/user'
        return "/user-management";
      } else {
        // If token doesn't exist, redirect to '/login'
        return "/login";
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/user-management",
    name: "user management",
    component: UserPage,
  },
  {
    path: "/user-info-detail/:id",
    name: "user detail",
    component: UserInfoDetail,
  },
  {
    path: "/building-management",
    name: "product management",
    component: ProductPage,
  },
  {
    path: "/buildling-detail/:id",
    name: "buildling management",
    component: ProductInfoDetail,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
// Add a global navigation guard
router.beforeEach((to, from, next) => {
  // If the route requires authentication and there's no token
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !JSON.parse(localStorage.getItem("crm-user"))?.token
  ) {
    // Redirect to login page
    next("/login");
  } else {
    next();
  }
});

export default router;
