<template>
  <div class="home_page">
    <userComp title="Home Page"></userComp>
  </div>
</template>
<script>
import UserComp from "@/components/UserManagementComp.vue";
export default {
  components: {
    userComp: UserComp,
  },
};
</script>
<style lang="scss">
.home_page {
  width: calc(100% - 56px);
}
</style>
