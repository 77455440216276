<template>
  <div id="product-info-detail">
    <div class="list-btn-header d-flex flex-row justify-content-between">
      <div class="button-back">
        <button @click="backToProductManagement()" class="common-btn mx-2">
          Trở về
        </button>
      </div>
      <div class="button-edit-infor">
        <button class="common-btn mx-2" v-b-modal.modal-add-product>
          Chỉnh sửa thông tin sản phẩm
        </button>
      </div>
    </div>
    <div class="user-info-and-required d-flex flex-row justify-content-between">
      <div class="user-info d-flex flex-column">
        <div class="row-info">
          <div class="item-info top-item-info">
            <div class="label-info">
              <span>Tên sản phẩm</span>
            </div>
            <div class="info d-flex flex-row">
              <span>{{ productInfo.name }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Vị trí</span></div>
            <div class="info d-flex flex-row">
              <span>
                {{ productInfo?.address?.detail
                }}<template v-if="productInfo?.address?.detail">, </template>
                {{ productInfo?.address?.ward
                }}<template v-if="productInfo?.address?.ward">, </template>
                {{ productInfo?.address?.district
                }}<template v-if="productInfo?.address?.district">, </template>
                {{ productInfo?.address?.province }}
              </span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Chủ đầu tư</span></div>
            <div class="info">
              <span>{{ productInfo?.product_detail?.owner }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Đơn vị quản lý</span></div>
            <div class="info">
              <span>{{ productInfo?.management_unit }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Diện tích sàn (Số) (m2)</span></div>
            <div class="info">
              <span>{{ productInfo?.product_detail?.floor_area }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Hướng</span></div>
            <div class="info">
              <span>{{ productInfo?.product_detail?.building_direction }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Hạng</span></div>
            <div class="info">
              <span>{{ productInfo?.rank }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Năm hoàn thành</span></div>
            <div class="info">
              <span>{{ productInfo?.product_detail?.completion_time }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Độ cao trần (m)</span></div>
            <div class="info">
              <span>{{ productInfo?.product_detail?.celling_height }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Kết cấu & thang máy</span></div>
            <div class="info d-flex flex-row">
              <span>{{ productInfo?.product_detail?.structure }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info">
              <span>Diện tích cho thuê (Mô Tả)</span>
            </div>
            <div class="info d-flex flex-row">
              <span>{{ productInfo?.product_detail?.empty_area_detail }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Giá thuê (Số) ($/m2)</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.basic_rent_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Phí dịch vụ (Số) ($/m2)</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.service_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info">
              <span>Giá thuê trọn gói (Số) ($/m2)</span>
            </div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.full_rent_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Phí gửi xe mô tô</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.motor_park_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Phí gửi xe ô tô</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.car_park_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Phí ngoài giờ</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.over_time_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Tiền điện</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.electronic_cost }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Tiền đặt cọc</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.rent_deposit }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Thanh toán</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.rent_area }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Thời hạn thuê</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.rent_time }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Thời gian trang trí</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.decor_time }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Phí môi giới</span></div>
            <div class="info">
              <span>{{ productInfo?.rent_detail?.brokerage_fee }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Người quản lý</span></div>
            <div class="info">
              <span>{{ productInfo?.manager_name }}</span>
            </div>
          </div>
        </div>
        <div class="row-info">
          <div class="item-info">
            <div class="label-info"><span>Số điện thoại quản lý</span></div>
            <div class="info">
              <span>{{ productInfo?.manager_phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="required">
        <div class="product-img">
          <template v-if="!imageUrl">
            <img
              class="default-logo"
              :src="require('@/assets/images/logo.png')"
            />
          </template>
          <template v-else>
            <img class="display-img" :src="imageUrl" alt="Image" />
          </template>
        </div>
        <div class="gg-map-relative">
          <div class="input-container">
            <div class="input-wrapper">
              <label for="lat">Vĩ độ:</label>
              <input
                id="lat"
                v-model="lat"
                @input="updateMap"
                type="number"
                step="any"
                class="input-field"
              />
            </div>
            <div class="input-wrapper">
              <label for="lng">Kinh độ:</label>
              <input
                id="lng"
                v-model="lng"
                @input="updateMap"
                type="number"
                step="any"
                class="input-field"
              />
            </div>
          </div>
          <div id="gg-map" class="map-container"></div>
        </div>
        <div class="document-info">
          <div class="row-info">
            <div class="item-info top-item-info">
              <div class="label-info">Người phụ trách</div>
              <div class="info">{{ productInfo?.saler_name }}</div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info">
              <div class="label-info">Link website</div>
              <div class="info" style="font-size: 12px">
                <a :href="productInfo?.website" target="_blank">{{
                  productInfo?.website
                }}</a>
              </div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info">
              <div class="label-info">Tài nguyên</div>
              <div class="info d-flex flex-row" style="padding: 8px 0px">
                <ul>
                  <li
                    class="list-file"
                    style="font-size: 12px"
                    v-for="(item, idx) in listFile"
                    :key="idx"
                  >
                    <a :href="item.value" target="_blank">{{ item.name }}</a>
                    <img
                      style="width: 20px; height: 20px; cursor: pointer"
                      @click="deleteFile(item)"
                      :src="require('@/assets/images/delete.png')"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row-info">
            <div class="item-info w-100">
              <div class="label-info">Ghi chú</div>
              <div class="info d-flex flex-row">
                <textarea
                  style="font-size: 16px; background-color: #fff"
                  class="w-100"
                  rows="4"
                  v-model="productInfo.note"
                  disabled
                >
                </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductInfoModal
      :productInfo="productInfo"
      @updateProductInfo="updateProductInfo"
      title="Cập nhập sản phẩm"
    ></ProductInfoModal>
  </div>
</template>

<script>
import ProductInfoModal from "@/components/common/ProductInfoModal.vue";
import { requestAsync } from "@/requester/requestAsync";
export default {
  data() {
    return {
      productInfo: {
        address: {
          detail: "",
          district: "",
          province: "Thành phố Hồ Chí Minh",
          ward: "",
          lat: 0,
          lng: 0,
        },
        created_at: "",
        deleted_at: 0,
        files: [],
        id: "",
        image: {
          contentType: "",
          created_at: "",
          deleted_at: 0,
          filename: "",
          id: "",
          productID: "",
          updated_at: "",
        },
        manager_name: "",
        manager_phone: "",
        name: "",
        note: "",
        product_detail: {
          area: 0,
          building_direction: "",
          celling_height: "",
          completion_time: "",
          empty_area: [0],
          empty_area_detail: "",
          floor_area: 0,
          manager_company: "",
          owner: "",
          structure: "",
        },
        rank: "",
        rent_detail: {
          basic_rent_cost: "",
          brokerage_fee: "",
          car_park_cost: "",
          decor_time: "",
          electronic_cost: "",
          full_rent_cost: "",
          motor_park_cost: "",
          over_time_cost: "",
          rent_area: "",
          rent_deposit: "",
          rent_time: "",
          service_cost: "",
        },
        updated_at: "",
        website: "",
      },
      selectedProductInfoId: null,
      imageUrl: null,
      lat: 0,
      lng: 0,
      map: null,
      marker: null,
      tileLayer: null,
    };
  },
  components: {
    ProductInfoModal,
  },
  methods: {
    backToProductManagement() {
      this.$router.push("/building-management");
    },
    async submitImageProduct(data) {
      let formData = new FormData();
      formData.append("file", data);

      let res = await requestAsync.post(
        "files/product/image?product_id=" + this.selectedProductInfoId,
        formData
      );
      console.log(res);
      this.getDataProductInfo();
    },
    async submitFileProduct(data) {
      let formData = new FormData();
      data.forEach(function (file) {
        // Append each file to the FormData object with the name "files[]"
        formData.append("file", file);
      });
      let res = await requestAsync.post(
        "files/product/files?product_id=" + this.selectedProductInfoId,
        formData
      );
      console.log(res);
      this.getDataProductInfo();
    },
    async deleteFile(item) {
      try {
        const res = await requestAsync.del(
          "files/product/files?product_id=" +
            this.selectedProductInfoId +
            "&file_name=" +
            item.name
        );
        if (!res.hasError) {
          // return true
          this.$toast.open({
            message: "Xoá file thành công!",
            type: "success",
            position: "top",
            duration: 2000,
          });
        } else {
          this.$toast.open({
            message: "Xoá file thất bại!",
            type: "error",
            position: "top",
            duration: 2000,
          });
        }
        this.getDataProductInfo();
      } catch {
        // do somthing
      }
    },
    convertToNumber(data) {
      // change format from string to number:
      console.log(data);
      console.log(typeof data);
      if (data && data.length > 1 && typeof data === "string") {
        data = data.split(",").map(Number);
        return data;
      } else if (data && data.length > 1 && typeof data === "object") {
        return data;
      }
      return [0];
    },
    async updateProductInfo(data) {
      let reqData = JSON.parse(JSON.stringify(this.productInfo));
      reqData.product_detail.empty_area = this.convertToNumber(
        reqData?.product_detail?.empty_area
      );
      reqData.product_detail.area = Number(reqData.product_detail.area);
      reqData.product_detail.floor_area = Number(
        reqData.product_detail.floor_area
      );
      let res = await requestAsync.put(
        "products/" + this.selectedProductInfoId,
        reqData
      );
      if (res?.id) {
        // return true
        this.$toast.open({
          message: "Chỉnh sửa thông tin sản phẩm thành công!",
          type: "success",
          position: "top",
          duration: 2000,
        });
        if (data.listFile) this.submitFileProduct(data.listFile);
        if (data.inputImage) this.submitImageProduct(data.inputImage);
        // update lat, long
        this.updateLongLat(res?.address?.lng, res?.address?.lat);
      } else {
        this.$toast.open({
          message: "Chỉnh sửa thông tin sản phẩm thất bại!",
          type: "error",
          position: "top",
          duration: 2000,
        });
        await this.getDataProductInfo();
      }
    },
    async getDataProductInfo() {
      // to do
      let res = await requestAsync.get(
        "products/" + this.selectedProductInfoId
      );
      if (res?.id) {
        this.productInfo = { ...res };
        this.updateLongLat(res?.address?.lng, res?.address?.lat);
        if (res?.image) {
          this.getProductImage();
        }
      }
    },
    async getProductImage() {
      this.imageUrl = null;
      let res = await requestAsync.get(
        "files/product?filename=" +
          this.productInfo.image.Filename +
          "&product_id=" +
          this.productInfo.image.ProductID
      );
      if (res) {
        this.imageUrl =
          process.env.VUE_APP_ORIGIN_API_URL +
          `/files/product?filename=${this.productInfo.image.Filename}&product_id=${this.productInfo.image.ProductID}`;
      }
    },
    async displayGGMap() {
      if (this.productInfo.address.lat && this.productInfo.address.lng) {
        this.lat = this.productInfo.address.lat;
        this.lng = this.productInfo.address.lng;
      } else {
        // convert address to lat, lng
        let address = [
          this.productInfo?.address?.detail,
          this.productInfo?.address?.ward,
          this.productInfo?.address?.district,
          this.productInfo?.address?.province,
        ]
          .filter(Boolean) // Remove any falsy values (undefined, null, empty string)
          .join(", "); // Join the remaining parts with ', '

        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const apiKey = "uQceV0xrdskWsO5eA7u6SMImNtFcjn-jV6RwIPijdpw";

        const url = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
          address
        )}&apiKey=${apiKey}`;
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (data.items.length > 0) {
          const firstItem = data.items[0];
          this.lat = firstItem.position.lat;
          this.lng = firstItem.position.lng;
        } else {
          return;
        }
        data.items.sort((a, b) => {
          const aScore =
            a.scoring.queryScore +
            Object.values(a.scoring.fieldScore).reduce(
              (acc, val) => acc + val,
              0
            );
          const bScore =
            b.scoring.queryScore +
            Object.values(b.scoring.fieldScore).reduce(
              (acc, val) => acc + val,
              0
            );
          return bScore - aScore;
        });

        if (data.items.length > 0) {
          const bestResult = data.items[0];
          this.lat = bestResult.position.lat;
          this.lng = bestResult.position.lng;
        } else {
          return;
        }
      }

      var L = window.L;
      this.map = L.map("gg-map").setView([this.lat, this.lng], 15);
      this.tileLayer = L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
          maxZoom: 18,
        }
      ).addTo(this.map);
      this.marker = L.marker([this.lat, this.lng]).addTo(this.map);
      this.updatePopup();
    },
    updateLongLat(lng, lat) {
      if (!lng || !lat) return;
      this.lng = lng;
      this.lat = lat;
      this.updateMap();
    },
    async updateMap() {
      if (this.map) {
        this.map.setView([this.lat, this.lng], 15);
        this.marker.setLatLng([this.lat, this.lng]);
        await this.updatePopup();
      }
    },
    async updatePopup() {
      try {
        const requestOptions = {
          method: "GET",
          redirect: "follow",
        };

        const apiKey = "uQceV0xrdskWsO5eA7u6SMImNtFcjn-jV6RwIPijdpw";

        // convert lat, lng from number to string, replace , by .
        const lat = this.lat.toString().replace(",", ".");
        const lng = this.lng.toString().replace(",", ".");

        const url = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&apiKey=${apiKey}`;
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        let popupContent = "Unknown location";

        if (data.items.length > 0) {
          popupContent = data.items[0].title;
        }
        this.marker.bindPopup(popupContent).openPopup();
      } catch (error) {
        this.marker.bindPopup("Error fetching location data").openPopup();
      }
    },
  },
  computed: {
    listFile() {
      let res = [];
      if (!this.productInfo.files || this.productInfo.files.length <= 0)
        return res;
      this.productInfo.files.forEach((element) => {
        res.push({
          name: element.Filename,
          value:
            process.env.VUE_APP_ORIGIN_API_URL +
            `/files/product?filename=${element.Filename}&product_id=${element.ProductID}`,
        });
      });
      return res;
    },
  },
  async created() {
    this.selectedProductInfoId = this.$route.params.id;
    await this.getDataProductInfo();
    await this.displayGGMap();
  },
};
</script>

<style lang="scss">
@import "@/assets/variable.scss";
#product-info-detail {
  padding: 20px 15px;
  .list-btn-header {
  }
  .user-info-and-required {
    .user-info,
    .required {
      margin-top: 30px;
      .user-info {
        background: #fff;
        padding: 15px 20px;
      }
      .row-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .top-item-info {
          border-top: solid 1px #000 !important;
        }
        .item-info {
          width: 100%;
          display: flex;
          border: solid 1px #000;
          border-top: unset;
          flex-direction: row;
          .label-info {
            width: 230px;
            min-width: 230px;
            color: $primary-color;
            font-size: 16px;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            border-right: solid 1px #000;
          }
          .info {
            width: 100%;
            font-size: 20px;
            display: flex;
            align-items: center;
            padding: 8px 10px;
            .status {
              color: #00813c;
            }
          }
          ul {
            padding-left: 15px;
          }
        }
      }
    }
  }
  .user-info {
    width: 58%;
    background: #fff;
  }
  .required {
    width: 40%;
    background: #fff;
    .product-img {
      img {
        width: 100%;
      }
      .default-logo {
        padding: 30px 20px;
        border-bottom: solid 1px #666666;
      }
      .display-img {
        aspect-ratio: 4 / 3; /* Automatically maintains a 4:3 ratio */
        object-fit: cover;
      }
    }
    .gg-map-relative {
      position: relative;
      padding: 20px;
      background-color: #f5f5f5;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .input-container {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }

    .input-wrapper {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    .input-wrapper label {
      margin-right: 10px;
      font-weight: bold;
    }

    .input-field {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 14px;
    }

    .map-container {
      width: 100%;
      height: 400px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    .document-info {
      padding: 20px;
    }
  }
  .services-process {
    padding: 20px;
    margin: 20px 0px;
    background: #fff;
    .process-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;
      .no {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
        background: $secondary-color;
        color: #fff;
        display: flex;
        justify-content: center;
        span {
          padding-top: 8px;
          font-size: 24px;
        }
      }
      .process-name,
      .process-detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .process-name {
        min-width: 250px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: bolder;
      }
      .process-detail {
        flex-grow: 1;
        min-height: 80px;
        padding: 10px 20px;
        margin: 0px 20px;
        background: #f5f5f5;
        border-radius: 3px;
      }
      .button-edit {
        min-width: 155px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .button-confirm {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        .fail-btn {
          background: $primary-color;
        }
      }
    }
  }
}
</style>
