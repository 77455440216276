<template>
  <div id="dashboard-comp">
    <div class="list-page">
      <div
        class="user-management-page item-page"
        @click="redirectTo(USER_MANAGEMENT.url)"
        :class="{ 'active-item-page': isMatchCurrentUrl(USER_MANAGEMENT.url) }"
      >
        <img :src="require('@/assets/images/user-management.png')" />
      </div>
      <div
        class="building-management-page item-page"
        @click="redirectTo(BUILDING_MANAGEMENT.url)"
        :class="{
          'active-item-page': isMatchCurrentUrl(BUILDING_MANAGEMENT.url),
        }"
      >
        <img :src="require('@/assets/images/building-management.png')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      USER_MANAGEMENT: {
        url: "/user-management",
      },
      BUILDING_MANAGEMENT: {
        url: "/building-management",
      },
    };
  },
  computed: {},
  methods: {
    isMatchCurrentUrl(url) {
      return url === this.$route.path;
    },
    redirectTo(url) {
      if (this.isMatchCurrentUrl(url)) return;
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss">
#dashboard-comp {
  background: #fff;
  min-width: 56px;
}
.item-page {
  padding: 10px;
  border-bottom: solid 2px #e2e2e2;
  cursor: pointer;
  img {
    max-width: 32px;
  }
}
.active-item-page {
  border: solid 2px #ff8c8c;
  background: #ff8c8c;
}
</style>
