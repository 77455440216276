export default [
  {
    text: "Đông",
    value: "Đông",
  },
  {
    text: "Tây",
    value: "Tây",
  },
  {
    text: "Nam",
    value: "Nam",
  },
  {
    text: "Bắc",
    value: "Bắc",
  },
  {
    text: "Đông Nam",
    value: "Đông Nam",
  },
  {
    text: "Đông Bắc",
    value: "Đông Bắc",
  },
  {
    text: "Tây Nam",
    value: "Tây Nam",
  },
  {
    text: "Tây Bắc",
    value: "Tây Bắc",
  },
];
