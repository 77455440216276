const namespaced = true;

const state = {
  token: "",
  member_id: "",
};
const getters = {
  getToken(state) {
    let localState = window.localStorage.getItem("crm-user");
    if (localState) {
      state.token = JSON.parse(localState).token;
    }
    return state.token;
  },
  getMemberId(state) {
    let localState = window.localStorage.getItem("crm-user");
    if (localState) {
      state.member_id = JSON.parse(localState).member_id;
    }
    return state.member_id;
  },
};
const mutations = {
  setToken(state, token) {
    state.token = token;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-user", saveState);
    return state.token;
  },
  setMemberId(state, member_id) {
    state.member_id = member_id;
    let saveState = JSON.stringify(state);
    window.localStorage.setItem("crm-user", saveState);
    return state.member_id;
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
};
