export default [
  {
    text: "A",
    value: "A",
  },
  {
    text: "B",
    value: "B",
  },
  {
    text: "C",
    value: "C",
  },
  {
    text: "Giá rẻ",
    value: "Giá rẻ",
  },
  {
    text: "Trọn gói",
    value: "Trọn gói",
  },
  {
    text: "Nguyên căn",
    value: "Nguyên căn",
  },
];
