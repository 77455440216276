<template>
  <div class="home_page">
    <ProductComp></ProductComp>
  </div>
</template>
<script>
import ProductComp from "@/components/ProductManagementComp.vue";
export default {
  components: {
    ProductComp: ProductComp,
  },
};
</script>
<style lang="scss">
.home_page {
  width: calc(100% - 56px);
}
</style>
